@font-face {
  font-family: "Modern Sans";
  font-style: normal;
  font-weight: normal;
  src: local("Modern Sans"), url("assets/modern-sans.woff") format("woff");
}

@import url("https://fonts.googleapis.com/css?family=Playfair+Display:900i");

.App {
  text-align: center;
}

.App-logo {
  height: 27vmin;
  margin-top: 55px;
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.modern-sans {
  font-family: "Modern Sans";
  width: 80%;
}

.top-welcome {
  margin-top: 55px;
  margin-bottom: 0px;
  font-size: 6vmin;
}

.playfair-display {
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: 900;
  font-size: 15vmin;
  margin-top: 0px;
  margin-bottom: 0px;
}

.bottom-welcome {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 4vmin;
}

.submit-container {
  margin-top: 10px;
}

.email-text-input {
  margin-top: 15px;
  height: 24px;
  width: 200px;
  border-radius: 5px;
}

.email-submit-button {
  margin-left: 3px;
  height: 30px;
  background-color: lightgreen;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  -webkit-appearance: none;
}

.email-submit-button:hover {
  opacity: 0.7;
}
